import fetch from 'cross-fetch'
import { defineStore } from 'pinia'
import {
  fetchStoryblokContent,
  getSiteProperties,
  getTheme,
} from '../api/storyblok'
import {
  fetchVacancy,
  fetchVacancyList,
  fetchCompany,
} from '../api/tooContentDelivery'
import {
  getVacancyIdFromPath,
  getJobAlertTokenFromPath,
  getCompanySlugFromPath,
} from '../utils/extractFromPath'
import { pickPublicationScope } from '../utils/publicationUtils.js'
import { saveFavorites } from '../utils/userUtils.js'

export const useMainStore = defineStore('main', {
  state: () => ({
    domainSlug: '',
    site: {},
    siteLoaded: false,
    theme: null,

    pageContent: null,
    pageContext: null,

    vacancy: null,
    company: null,
    publication: null,

    // Can be used as a badge in links and buttons
    siteVacanciesTotal: 0,
    siteVacanciesFetched: false,

    // Used for A/B testing
    features: {},

    // Used only in sites that have authorization enabled
    authInitialized: false,
    authCheckFinished: false,
    auth: null,
    userEmail: null,
    userToken: null,

    // Current user's favorite vacancies. Saved in localStorage
    favorites: [],
  }),
  getters: {
    abTestBlocks: (state) => {
      return (
        state.pageContent?.blocks.filter(
          (block) => block.component === 'ABTest',
        ) || []
      )
    },
    themeVariables: (state) => {
      const legacyThemeVariables = state.theme || state.site?.theme || {}
      const legacyColorPrefixes = [
        '--primary',
        '--secondary',
        '--tertiary',
        '--fourth',
        '--gray',
      ]

      const themeVariables = {}

      let includesLegacyColors = false
      for (const [key, value] of Object.entries(legacyThemeVariables)) {
        if (key.startsWith('--')) {
          // We also use variables for handling fonts, so we need to check if it's a color variable
          const isLegacyColor = legacyColorPrefixes.find((prefix) =>
            key.startsWith(prefix),
          )

          if (
            !isLegacyColor ||
            !legacyThemeVariables?.dontUseDeprecatedColors
          ) {
            themeVariables[key] = value

            if (key.includes('primary-500')) {
              includesLegacyColors = true
              themeVariables['--default'] = value
            }
          }
        }
      }

      state.theme?.palette?.forEach((color) => {
        themeVariables[`--${color.name}`] = color.value

        if (!includesLegacyColors && color.default) {
          themeVariables['--default'] = color.value
          // TODO Improve handling of secondary color
          // A small group of blocks have the secondary color hardcoded for their links/buttons
          themeVariables['--secondary-500'] = color.value
          themeVariables['--secondary-600'] = color.value
        }
      })

      // Add grey scale if it's not defined
      if (!includesLegacyColors) {
        // This colors are from the color scheme of Gemeentebanen, used by default in the previous theme
        themeVariables['--gray-100'] = '#efefef'
        themeVariables['--gray-200'] = '#dfdfdf'
        themeVariables['--gray-300'] = '#d0d0d0'
        themeVariables['--gray-400'] = '#c0c0c0'
        themeVariables['--gray-500'] = '#b0b0b0'
        themeVariables['--gray-600'] = '#8d8d8d'

        if (themeVariables['--dark-text']) {
          themeVariables['--gray-700'] =
            `color-mix(in oklab, ${themeVariables['--dark-text']}, white 20%)`
          themeVariables['--gray-800'] = themeVariables['--dark-text']
          themeVariables['--gray-900'] =
            `color-mix(in oklab, ${themeVariables['--dark-text']}, black 35%)`
        } else {
          themeVariables['--gray-700'] = '#6a6a6a'
          themeVariables['--gray-800'] = '#464646'
          themeVariables['--gray-900'] = '#232323'
        }
      }

      // We add the default colors
      themeVariables['--black'] = '#000000'
      themeVariables['--white'] = '#FFFFFF'
      themeVariables['--transparent'] = 'transparent'

      return themeVariables
    },
  },
  actions: {
    async fetchPageContent(pageContext) {
      let companySlug
      let vacancyId = getVacancyIdFromPath(
        pageContext.path,
        this.site?.vacancyLinkFormat,
      )
      const jobAlertToken = getJobAlertTokenFromPath(pageContext.path)
      const isJobAlertUnsubscribePage = !!jobAlertToken

      let fallbackPath = '/error-404'
      if (vacancyId) {
        fallbackPath = '/_default/_vacancy'
      } else {
        companySlug = getCompanySlugFromPath(
          pageContext.path,
          this.site?.companyLinkFormat,
        )
        if (companySlug) {
          fallbackPath = '/_default/_company'
        }
      }

      if (isJobAlertUnsubscribePage) {
        fallbackPath = '/_default/_unsubscribe'
      }

      // Remove query params from Storyblok's story path
      const storyPath = pageContext.path.split('?')[0]
      const enhancedContext = {
        ...pageContext,
        path: storyPath,
        domainSlug: this.domainSlug,
        baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
        vacancyId,
        jobAlertToken,
        fallbackPath,
      }

      try {
        let pageContent = await fetchStoryblokContent(enhancedContext)
        if (pageContent.dataVacancyId) {
          vacancyId = pageContent.dataVacancyId
        }
        if (pageContent.dataCompanySlug) {
          companySlug = pageContent.dataCompanySlug
        } else if (
          companySlug &&
          !pageContent.slug.includes('/_default/_company')
        ) {
          // If we're not using the company template, and there's no company slug set at Page level, we ignore the company slug
          // we extracted from the url
          companySlug = null
        }

        const { vacancy, company, publication } = await this.fetchTooData({
          vacancyId,
          companySlug,
        })

        // Go to 404 page if we can't find the vacancy and there's a vacancy ID available
        if (vacancyId && !vacancy && fallbackPath !== '/error-404') {
          console.log(
            `Vacancy with ID ${vacancyId} not found, display the 404 page`,
          )
          pageContent = await fetchStoryblokContent({
            ...enhancedContext,
            vacancyId: null,
            fallbackPath: '/error-404',
          })
        }

        // We update the store after all requests are done so we don't trigger multiple changes in the UI
        this.$patch({
          pageContent,
          pageContext: enhancedContext,
          vacancy,
          company,
          publication,
        })
      } catch (e) {
        this.pageContent = null
        this.pageContext = enhancedContext

        throw e
      }
    },
    async fetchSite() {
      if (this.site.name) return

      try {
        const { data } = await checkApiRes(
          await fetch(
            `${import.meta.env.VITE_BASE_TOO_API_URL}/content-delivery/v1/${
              this.domainSlug
            }/sites`,
            {},
          ),
        )

        // theme is provided as a stringified JSON, so we convert it to a JS object
        if (data.theme && typeof data.theme === 'string') {
          data.theme = JSON.parse(data.theme)
        }
        this.site = data
      } catch (err) {
        console.error(err)
      } finally {
        this.siteLoaded = true
      }
    },
    async fetchTheme(params) {
      if (this.theme) return

      const theme = await getTheme({ ...params, domainSlug: this.domainSlug })
      this.theme = theme
    },
    async fetchTooData({ vacancyId, companySlug }) {
      const fetchPromises = []

      let vacancyData,
        companyData,
        publicationData = null

      try {
        if (companySlug) {
          fetchPromises.push(
            fetchCompany({
              companySlug,
              domainSlug: this.domainSlug,
              baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
            }).then((company) => {
              companyData = company
            }),
          )
        }
        if (vacancyId) {
          fetchPromises.push(
            fetchVacancy({
              vacancyId,
              jwt: null, // This runs on the server, so the user is not authenticated yet
              domainSlug: this.domainSlug,
              baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
            }).then((vacancy) => {
              vacancyData = vacancy
              addContactFirstAndLastName(vacancyData)
              formatVacancySalaries(vacancyData, this.theme)
              publicationData = pickPublicationScope(vacancyData)

              if (!companySlug) {
                return fetchCompany({
                  companySlug: vacancyData.company.slug,
                  domainSlug: this.domainSlug,
                  baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
                }).then((company) => {
                  companyData = company
                })
              }
            }),
          )
        }

        const results = await Promise.allSettled(fetchPromises)
        results.forEach((result) => {
          if (result.status === 'rejected') {
            console.error(result.reason)
          }
        })

        return {
          vacancy: vacancyData,
          company: companyData,
          publication: publicationData,
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.siteLoaded = true
      }
    },
    getTotalSiteVacancies() {
      if (this.siteVacanciesFetched) {
        return
      }

      this.siteVacanciesFetched = true
      fetchVacancyList({
        searchParams: {},
        page: {
          page: 1,
          size: 999,
        },
        jwt: this.userToken,
        domainSlug: this.domainSlug,
        baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
      }).then(({ data }) => {
        this.siteVacanciesTotal = data?.length || 0
      })
    },
    async initAuthentication(isCallback) {
      if (this.authInitialized) {
        return
      }

      this.authInitialized = true
      const [props] = await Promise.all([
        getSiteProperties(this.domainSlug),
        loadAuth0(),
      ])

      // We check that the site has configured all the auth properties required
      if (!props.auth_domain || !props.auth_client_id || !props.auth_audience) {
        console.error(
          'Missing configuration, authentication could not be initialized.',
        )
        // TODO Send specific error to Sentry?
        this.authCheckFinished = true
        return false
      }

      return new Promise((resolve) => {
        this.auth = new window.auth0.WebAuth({
          domain: props.auth_domain,
          clientID: props.auth_client_id,
          responseType: 'token id_token',
          scope: 'openid email',
          redirectUri: `${window.location.origin}/sb-callback`,
          audience: props.auth_audience,
        })

        if (!isCallback) {
          this.auth.checkSession({}, (err, authResult) => {
            if (err) {
              console.log(err)
              this.authCheckFinished = true
              resolve(true)
            } else {
              this.auth.client.userInfo(
                authResult.accessToken,
                async (err, user) => {
                  if (err) {
                    console.log(err)
                  } else {
                    this.userEmail = user.email
                    this.userToken = authResult.accessToken

                    // TODO Check that this works. Also, is it possible that a single is not returned
                    // unless the user is authorized?
                    // if (this.vacancy) {
                    //   const vacancy = await fetchVacancy({
                    //     vacancyId: this.vacancy.id,
                    //     jwt: this.userToken,
                    //     domainSlug: this.domainSlug,
                    //     baseTooUrl: import.meta.env.VITE_BASE_TOO_API_URL,
                    //   })
                    //   this.publication = pickPublicationScope(vacancy)
                    //   this.pageContext.publication = this.publication
                    // }
                  }

                  this.authCheckFinished = true
                  resolve(true)
                },
              )
            }
          })
        } else {
          this.auth.parseHash({ hash: window.location.hash }, (err) => {
            if (err) {
              console.log(err)
            } else {
              // TODO Redirect to where it was
              window.location = '/'
            }
            resolve(true)
          })
        }
      })
    },
    logout() {
      this.auth?.logout({ returnTo: window.location.origin })
      this.userEmail = null
      this.userToken = null
    },
    toggleFavorite(vacancy) {
      const index = this.favorites.findIndex(
        (favorite) => favorite.id === vacancy.id,
      )
      if (index > -1) {
        this.favorites.splice(index, 1)
      } else {
        // We use this structure to keep this backwards compatible
        this.favorites.push({
          id: vacancy.id,
          title: vacancy.title,
          organization: vacancy.company.title,
          vacancySlug: vacancy.slug,
          companySlug: vacancy.company.slug,
        })
      }
      // Save to local storage
      saveFavorites(this.favorites)
    },
  },
})

const checkApiRes = async (response) => {
  const data = await response.json()
  // check for error response
  if (!response.ok) {
    // get error message from body or default to response statusText
    const error = (data && data.message) || response.statusText
    return Promise.reject(error)
  }
  return data
}

const addContactFirstAndLastName = (vacancy) => {
  if (vacancy?.contactPerson?.name) {
    const nameParts = vacancy?.contactPerson?.name.split(' ')
    vacancy.contactPerson = {
      ...vacancy.contactPerson,
      firstName: nameParts[0],
      lastName: nameParts.slice(1).join(' '),
    }
  }
}

const formatVacancySalaries = (vacancy, theme) => {
  if (vacancy?.salary) {
    const salary = { ...vacancy.salary }
    const locale = theme?.locale || 'nl'

    salary.minFormatted = !theme?.notFormatCurrency
      ? new Intl.NumberFormat(locale).format(salary.min)
      : salary.min
    salary.maxFormatted = !theme?.notFormatCurrency
      ? new Intl.NumberFormat(locale).format(salary.max)
      : salary.max

    vacancy.salary = salary
  }
}

const loadAuth0 = async () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = 'https://cdn.auth0.com/js/auth0/9.28.0/auth0.min.js'
    script.type = 'text/javascript'
    script.async = true

    script.onload = () => {
      resolve()
    }
    script.onerror = () => {
      reject()
    }

    document.head.appendChild(script)
  })
}
